import { Routes, Route } from "react-router-dom";

import { HomeSection } from "../components/dashboard/sections/home/HomeSection";
import { SettingsSection } from "../components/dashboard/sections/settings/SettingsSection";
import { NotificationsSection } from "../components/dashboard/sections/notifications/NotificationsSection";
import { RecaudacionSection } from "../components/dashboard/sections/recaudacion/RecaudacionSection";
import { OperacionSection } from "../components/dashboard/sections/operacion/OperacionSection";
import { ConservacionSection } from "../components/dashboard/sections/conservacion/ConservacionSection";
import { ReportesSection } from "../components/dashboard/sections/reportes/ReportesSection";

export const DashboardRouter = () => {
	return (
		<div className="container">
			<Routes>
				<Route path="home" element={<HomeSection />} />
				<Route path="recaudacion" element={<RecaudacionSection />} />
				<Route path="operacion" element={<OperacionSection />} />
				<Route path="conservacion" element={<ConservacionSection />} />
				<Route path="reportes" element={<ReportesSection />} />
				<Route path="settings" element={<SettingsSection />} />
				<Route path="notifications" element={<NotificationsSection />} />
				<Route path="/" element={<HomeSection />} />
			</Routes>
		</div>
	);
};
